(() => {
  'use strict';

  // Const
  // -----
  const MAX_VIEWPORT_WIDTH = 1920;

  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.outerHeight();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;


  // Defaults
  // --------
  let slickBaseSettings = {
        elementAsContext: true,
        slider: '> div:first-child',
        appendArrows: '.slick-arrows',
        appendDots: '.slick-dots',
        dotsClass: '',
        autoplay: true,
        autoplaySpeed: 8000,
        speed: 300,
        centerMode: false,
        centerPadding: '0px',
        cssEase: 'ease-in-out',
        prevArrow: '<div class="button-style active prev"></div>',
        nextArrow: '<div class="button-style active next"></div>',
        pauseOnDotsHover: true,
        customPaging: () => '<div></div>',
        draggable: true,
        swipeToSlide: true,
        touchThreshold: 100,
      },
      slickSliderSettings = $.extend({}, slickBaseSettings, {
        autoplay: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: DESKTOP_WIDTH,
            settings: slick => ({
              slidesToShow: slick.originalSettings.slidesToShow > 3 ? 3 : slick.originalSettings.slidesToShow,
            })
          },
          {
            breakpoint: LANDSCAPE_SMALL_TABLET_WIDTH,
            settings: slick => ({
              slidesToShow: slick.originalSettings.slidesToShow > 2 ? 2 : slick.originalSettings.slidesToShow,
            })
          },
          {
            breakpoint: 600,
            settings: slick => ({
              adaptiveHeight: true,
              slidesToShow: /[^\B-](team|advantages)[^\B-]/i.test(slick.$container.attr('class')) ? 2 : 1,
            })
          },
        ],
      });


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '\\+1 \\(\\d{3}\\) \\d{3}\\-\\d{4}',
      placeholder: '+1 (___) ___-____'
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');



  $WINDOW
    .one('load', () => {
      $WINDOW.trigger('calcSizes');
      $DOCUMENT.trigger('headerEvents');
    })
    .on('structureScale', () => {
      $('.structure').css('transform', (VIEWPORT_WIDTH > MAX_VIEWPORT_WIDTH ? 'scale('.concat(VIEWPORT_WIDTH / MAX_VIEWPORT_WIDTH, ')') : ''));
    })
    .on('resize', () => {
      $WINDOW.trigger('structureScale');
      $DOCUMENT.trigger('headerEvents');
    })
    .trigger('structureScale');


  $DOCUMENT
    .ready(() => {
      // Open popup form by hash
      // -----------------------
      let hashPopupMatches = location.hash.slice(1).match(/^popup:([\w\-]+)/i);

      if (hashPopupMatches && hashPopupMatches[1].length) {
        let $hashPopup = $('#'.concat(hashPopupMatches[1]))

        if ($hashPopup.length && $hashPopup.is(':hidden'))
          $.fancybox.open({type: 'inline', src: $hashPopup});
      }


      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', e => {
          e.currentTarget.checked ? $BODY.addClass('menu-is-open') : $BODY.removeClass('menu-is-open');
        })
        .prop({'checked': false, 'disabled': false})
        .trigger('change');


      // Main rotator
      // ------------
      $('.main-rotator')
        .on('beforeChange', (e, slick, currentSlide, targetSlide) => {
          slick.$slides.eq(targetSlide).find('> div:first-child > div').removeClass('dn').trigger('unveil.unveil');
        })
        .slick($.extend({}, slickBaseSettings, {
          fade: true,
          adaptiveHeight: true,
        }));

      // Other sliders
      // -------------
      $('.slick.active').slick(slickSliderSettings);


      // Audio players
      // -------------
      GreenAudioPlayer.init({
        selector: '.js-audio-player',
        stopOthersOnPlay: true,
      });


      // Lazy load
      // ---------
      $('.main-rotator-slide > div:first-child > div')
        .unveil({
          attribute: 'lazySrc',
          placeholder: '',
          offset: 0,
          throttle: 200,
          breakpoints: [
            {
              minWidth: LANDSCAPE_SMALL_TABLET_WIDTH,
              attribute: 'lazySrcLg'
            },
            {
              minWidth: 600,
              attribute: 'lazySrcMd'
            },
          ]
        });

      $('.js-visible, .breadcrumbs, .subheader, .subsubheader, .subheader-top, .incut, .incut-image, .dark-bg, .grey-bg-2, .circle-bg, .tab-list, .vitrine-group-text, .vitrine-grouped, div:not(.vitrine-grouped) > .slick, div:not(.vitrine-grouped) > .vitrine, .vitrine-item-image [data-lazy-src], .price-table-items, .portfolio-top-info, .gallery-params, .gallery-notice, .gallery > div > div > div + div, .photo-text .vitrine-item-image img, .video-frame > a')
        .unveil({
          attribute: 'lazySrc',
          placeholder: '',
          offset: 55,
          throttle: 200
        });

      $('.gallery img')
        .unveil({
          attribute: 'lazySrc',
          placeholder: '',
          offset: 55,
          throttle: 200
        })
        .on('unveil.unveil', e => {
          $(e.currentTarget).closest('div').addClass('unveil-loaded');
        });


      // jQuery.UI sliders
      // -----------------
      $('.js-slider input').each((index, element) => {
        let $input = $(element),
            value = $input.val(),
            min = $input.data('min') || 0,
            max = $input.data('max') || 100,
            step = $input.data('step') || 1;

        $input.closest('.js-slider').find('> div > div').slider({
          classes: {
            'ui-slider': '',
            'ui-slider-handle': '',
            'ui-slider-range': ''
          },
          range: false,
          value: value,
          step: step,
          min: min,
          max: max,
          create: e => {
            let $slider = $(e.target);

            $slider.prev().text(number_format(min));
            $slider.next().text(number_format(max));
            $slider.find('.ui-slider-handle').html('<span><span>'.concat(number_format(value), '</span></span>'));
          },
          slide: (e, ui) => {
            $(ui.handle).html('<span><span>'.concat(number_format(ui.value), '</span></span>'));
          },
          change: (e, ui) => {
            $(ui.handle).html('<span><span>'.concat(number_format(ui.value), '</span></span>'));
            $input.val(ui.value).trigger('change');
          }
        });
      });


      // jQuery.UI spinners
      // ------------------
      /*$.widget('ui.customSpinner', $.ui.spinner, {
        _enhance: function() {
          this.uiSpinner = this.element
            .attr('autocomplete', 'off')
            .wrap(this._uiSpinnerHtml())
            .parent().parent().append(this._buttonHtml());
        },
        _uiSpinnerHtml: () => '<div>',
        _buttonHtml: () => '<div class="ui-spinner-button ui-spinner-up"><div><img src="/i/sp.svg" /></div></div><div class="ui-spinner-button ui-spinner-down"><div><img src="/i/sp.svg" /></div></div>',
      });

      $('.js-spinner input')
        .each((index, element) => {
          let $input = $(element),
              min = $input.data('min') || 1,
              max = $input.data('max') || null,
              step = $input.data('step') || 1;

          $input
            .val((index, value) => (value < min ? min : value))
            .customSpinner({
              culture: 'en',
              min: min,
              max: max,
              page: 0,
              step: step,

              change: e => {
                e.preventDefault();

                let _min = $(e.target).data('min') || 1;

                if ($(e.target).val() < _min)
                  $(e.target).val(_min).trigger('change');
              },
              spin: (e, ui) => {
                e.preventDefault();

                $(e.target).val(ui.value).trigger('change');
              }
            });
        })
        .off('cut copy paste')
        .on('cut copy paste', e => {
          e.preventDefault();
        });*/
    })

    // Scroll to
    // ---------
    .on('scrollTo', (e, $element, speed, offset) => {
      if ($element === undefined)
        $element = $(e.target);

      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 200;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset) - (IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT)});
    })
    .on('click.jsScrollTo', '.js-scroll-to', e => {
      e.preventDefault();

      let $link = $(e.currentTarget),
          $element = $($link.attr('href').substring($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    })

    // Header events
    // -------------
    .on('headerEvents', () => {
      if (IS_DESKTOP_WIDTH()) {
        if (!$BODY.hasClass('is-desktop-events')) {
          $BODY
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')
            .css('padding-top', '');

          $HEADER.removeClass('fixed');

          $('.header-menu-wrapper .socials').appendTo('.header-top-left');
          $('.header-menu-wrapper .header-phones').prependTo('.header-top-right');

          // Header menu desktop events
          // --------------------------
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .parent().css('top', '')
            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');

          // Fix header submenu popup position
          // ---------------------------------
          let headerMenuWidth = $HEADER_MENU.outerWidth();

          $HEADER_MENU.find('> ul > li').each((i, element) => {
            let $item = $(element),
                $submenu = $item.find('> div > ul');

            if ($submenu.length) {
              $submenu.css('max-width', headerMenuWidth);

              let position = headerMenuWidth - $submenu.outerWidth() - $item.position().left;

              if (position < 0)
                $submenu.css('left', position);
            }
          });
        }
      }
      else {
        if (!$BODY.hasClass('is-mobile-events')) {
          $BODY
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          $HEADER.addClass('fixed');

          $('.header-top .socials, .header-top .header-phones').appendTo('.header-menu-wrapper > div + div');

          // Header menu mobile events
          // -------------------------
          $HEADER_MENU
            .find('ul').removeAttr('style');

          $DOCUMENT
            // Close header menu
            .on('click.closeHeaderMenu', e => {
              if (!($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu-button-wrapper + div').length)) {
                e.stopPropagation();

                $MENU_CHECKBOX.prop('checked', false).trigger('change');
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu > ul a, .header-menu > ul span, .header-menu > ul .dropmarker', e => {
              e.preventDefault();

              let $self = $(e.currentTarget),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              $parent.siblings().removeClass('is-hovered').find('> div > ul').slideUp(300);

              if (isWithDropdown) {
                if (isOnHover) {
                  if ($self.prop('tagName').toUpperCase() === 'A')
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').find('> div > ul').slideUp(300);
                }
                else
                  $parent.addClass('is-hovered').find('> div > ul').slideDown(300);
              }
              else {
                if ($self.prop('tagName').toUpperCase() === 'A')
                  location.href = $self.prop('href');
              }
            });
        }

        $BODY.css('padding-top', HEADER_HEIGHT);
        $HEADER_MENU.parent().css('top', HEADER_HEIGHT);
      }
    })
    .on('click.headerCallbackLink', '.header-top-right .callback-link a', e => {
      e.preventDefault();

      $.fancybox.open({
        type: 'inline',
        src: IS_DESKTOP_WIDTH() ? '#callback-popup-form' : '#contacts-popup',
      });
    })


    // Input events
    // ------------
    // Not empty validation
    .on('input', '.js-not-empty, .js-not-empty-with-trigger', e => {
      let $element = $(e.currentTarget),
          value = $element.val(),
          defaultValue = $element.data('defaultValue');

      if (!$.isNumeric(defaultValue))
        defaultValue = 1;

      if (!value)
        $element.val(defaultValue);

      if ($element.hasClass('js-not-empty-with-trigger'))
        $element.trigger('change');
    })
    // Minimum numeric value
    .on('input', '.js-min-value, .js-min-value-with-trigger', e => {
      let $element = $(e.currentTarget),
          value = $element.val(),
          minValue = $element.data('min');

      if (!$.isNumeric(minValue))
        minValue = 1;

      if (parseFloat(value) < parseFloat(minValue))
        $element.val(minValue);

      if ($element.hasClass('js-min-value-with-trigger'))
        $element.trigger('change');
    })

    // jQuery.UI slider input change
    .on('input', '.js-slider input:visible', e => {
      $(e.currentTarget).closest('.js-slider').find('> div > div').slider('value', e.currentTarget.value);
    })


    // Slick sliders events
    // --------------------
    .on('init', '.slick', (e, slick) => {
      slick.$container.addClass('slick-initialized');
    })
    .on('beforeChange', '.slick', (e, slick, currentSlide, targetSlide) => {
      if (IS_DESKTOP_WIDTH() && slick.$container.data('scrollOnChange'))
        slick.$container.trigger('scrollTo');

      if (slick.currentDirection && targetSlide > currentSlide || !slick.currentDirection && targetSlide < currentSlide) {
        let index = slick.currentDirection ? targetSlide - slick.slideCount : targetSlide + slick.slideCount,
            selector = '';

        for(let i = 0; i < slick.options.slidesToShow; i++, index++)
          selector += (selector.length ? ', ' : '').concat('[data-slick-index="', index, '"]');

        slick.$slideTrack.children().filter(selector).addClass('slick-active-fix');
      }

      if (slick.currentDirection && slick.$dots)
        slick.$dots.addClass('invert-fix');
    })
    .on('afterChange', '.slick', (e, slick) => {
      slick.$slideTrack.children().removeClass('slick-active-fix');

      if (slick.$dots)
        slick.$dots.removeClass('invert-fix');
    })
    .on('slickReInit', e => {
      let $sliders = (e.target.classList.contains('slick') ? $(e.target) : $(e.target).find('.slick.active'));

      if ($sliders.length) {
        $sliders.filter('.slick-initialized').slick('setPosition');
        $sliders.not('.slick-initialized').slick(slickSliderSettings);
      }
    })


    // Video frame load
    // ----------------
    .on('click.videoFrame', '.video-frame > a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget),
          $iframe = $self.next();

      $iframe.attr('src', () => $iframe.data('src'));

      if ($self.data('popup') !== undefined)
        $.fancybox.open($iframe);
      else
        $iframe.parent().addClass('active');
    })


    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget);

      if( !$self.hasClass('active') ) {
        let $container = $self.closest('.js-tabs'),
            $links = $container.find('a'),
            $destination = !!$container.data('tabsDest') ? $($container.data('tabsDest')) : $container.next(),
            cssClass = $container.data('tabCssClass') || 'active';

        $self.addClass('active');
        $links.not($self).removeClass('active');
        $destination.children().removeClass(cssClass).eq($links.index($self)).addClass(cssClass).trigger('slickReInit');
      }
    })


    // Response popup
    // --------------
    .on('click.response', '.responses .vitrine-item > div', e => {
      e.preventDefault();

      $.fancybox.open('<div class="form-popup-container"><div class="form-block-container response-popup">'.concat($(e.currentTarget).closest('.vitrine-item').html(), '</div></div>'));
    })


    // Callback price forms
    // --------------------
    .on('change.callbackPriceFormInputChange', '.form .form-steps .step', e => {
      $(e.currentTarget).closest('.step').find('.form-buttons .button-next').removeClass('disabled').removeAttr('disabled');
    })
    .on('callbackPriceFormAfterStepChange', e => {
      let $step = $(e.target);

      $step.closest('.form-container').find('.form-progress > div').css('width', ($step.index('.step') / $step.siblings('.step').length * 100).toString().concat('%'));
      $step.closest('.fancybox-slide').scrollTop(0);
    })
    .on('click.callbackPriceFormStepChange', '.form .form-steps .step .button-prev, .form .form-steps .step .button-next', e => {
      e.preventDefault();

      let method = (e.currentTarget.classList.contains('button-prev') ? 'prev' : 'next');

      $(e.currentTarget).closest('.step').removeClass('active')[method]('.step').addClass('active').trigger('callbackPriceFormAfterStepChange');
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: (widget, current) => {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: (widget, current) => {
      if (current.contentType == 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH())
        current.$content.find('.js-autofocus-inp').trigger('focus');
    }
  });
})();